<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="agents_product_name" slot-scope="agents_product_name, record">
        <router-link
          :to="{ name: 'agent_monthly_bill_card_use_info',
                 params: { agentId: agentId, card_use_id: record.product_usage_order_id},
                 query: { bill_id: billId }}"
        >
          {{ agents_product_name }}
        </router-link>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a v-if="isHasAdjustPermission && record.status_slug == 'finished'" @click="showAdjustModal(record.agent_bill_id)">调账</a>
          <a @click="showHistoryModal(record.agent_bill_id)">历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 历史模态框 -->
    <show-agent-bill-changed-history
      v-if="isShowHistoryModal"
      :visible.sync="isShowHistoryModal"
      :id="showingHistoryAgentBillId"
    />

    <!-- 调账模态框-->
    <adjust-agent-bill
      v-if="isShowAdjustModal"
      :visible.sync="isShowAdjustModal"
      :agent-bill-id="showingAdjustAgentBillId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findCardUseBills } from '@/api/agent_monthly_bill'
import { hasPermission } from '@/utils/permission'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentPurchaseBillList',
  components: {
    Pagination,
    ShowAgentBillChangedHistory: () => import('@/views/agent_bill_changed_histories/index'),
    AdjustAgentBill: () => import('@/views/agent_monthly_bills/Adjust')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowHistoryModal: false, // 是否显示历史模态框
      showingHistoryAgentBillId: 0, // 正在展示的历史数据agentBillId
      isShowAdjustModal: false, // 是否显示调账模态框
      showingAdjustAgentBillId: 0, // 正在展示的调账数据agentBillId
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        { title: '套餐名称', dataIndex: 'agents_product_name', scopedSlots: { customRender: 'agents_product_name' }},
        { title: '单元类型', dataIndex: 'user_business_type' },
        { title: '活跃卡号总数', dataIndex: 'activated_card_count', customRender: formatBigNumber },
        { title: '超额费用(元)', dataIndex: 'exceed_fee', customRender: formatCurrency },
        { title: '总费用(元)', dataIndex: 'total_fee', customRender: formatCurrency },
        { title: '备注', dataIndex: 'remark', ellipsis: true },
        { title: '操作', dataIndex: 'actions', key: 'action', fixed: 'right', scopedSlots: { customRender: 'actions' }}
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    },
    isHasAdjustPermission() {
      return hasPermission('agent_bills/adjust')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showHistoryModal(agentBillId) {
      this.isShowHistoryModal = true
      this.showingHistoryAgentBillId = agentBillId
    },

    showAdjustModal(id) {
      this.showingAdjustAgentBillId = id
      this.isShowAdjustModal = true
    },

    fetchData() {
      this.loading = true
      findCardUseBills(this.billId, this.query).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

